<script lang="tsx">
import { defineComponentBaseUiFormHelp } from '@core/app/components/base/ui/form/BaseUiFormHelp.vue'

export default defineComponentBaseUiFormHelp()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormHelp" as *;

@include help {
    @include mon-text-small;

    color: $mon-c-black;
}

</style>
