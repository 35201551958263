<script lang="tsx">
import { defineComponentBaseUiFormError } from '@core/app/components/base/ui/form/BaseUiFormError.vue'

export default defineComponentBaseUiFormError()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormError.scss" as *;

@include error {
    @include mon-text-extra-small;
    color: $mon-c-danger-500;
}

</style>
