<script lang="tsx">
import { defineComponentBaseUiFormLabel } from '@core/app/components/base/ui/form/BaseUiFormLabel.vue'

export default defineComponentBaseUiFormLabel()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormLabel" as *;

@include label {
    color: $mon-c-black;

    @include mon-text-small;
}

@include label--normal-text {
    @include mon-text-small;
}

@include required-marker {
    color: $mon-c-danger-500;
}

</style>
