<script lang="tsx">
import { defineComponentBaseUiFormDescription } from '@core/app/components/base/ui/form/BaseUiFormDescription.vue'

export default defineComponentBaseUiFormDescription()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormDescription" as *;

@include description {
    @include mon-text-extra-small;

    color: $mon-c-black;
}

</style>
